<template>
  <div>
    <hero-global img="/img/clarity/clarity-1.svg" title="Transparencia" />
    <section class="w-100 d-block text-center container container-tranparencia-editor-js">
      <EditorJs v-if="entry" :object="entry.body" />
    </section>

    <div class="p-x-2">
      <h2 class="title-grand p-0 m-0 container">Información</h2>
    </div>
    <div class="w-100 d-block background-blue-3 p-3-rem">
      <div class="text-center container">
        <p class=" w-50 m-bottom-10-px">Así, en el canal CERMI (http://www.cermi.es) de
          esta página de Internet, puede hallarse información y documentación completas sobre:</p>
      </div>
      <div class="container">
        <div class="background-white p-2-rem shadow ">
          <p class="text-bold font-size-21">Información institucional, organizativa y de planificación (Art. 6 Ley
            19/2013 de 9 de diciembre)</p>

          <div class="d-flex">
            <ul class="list-style-none m-right-2-rem">
              <li class="font-size-21 text-primary font-light ">
                <a class="text-primary text-decoration-none" target="_blank" href="/que-es-cermi/plataforma-unitaria-discapacidad">Carácter del CERMI/ Plataforma unitaria de
                  la discapacidad</a>
              </li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/que-es-cermi#mision-vision-valores">Misión y Objetivos del CERMI</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/iniciativas-cermi ">Ámbitos de actuación</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/organizaciones/socios-fundadores">Organizaciones del CERMI Estatal</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/organizaciones/organizacioes-pertenencia">Organizaciones a las que el CERMI pertenece</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/cermis-autonomicos">CERMIS Autonómicos</a></li>
            </ul>
            <ul class="list-style-none">
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/asi-nos-organizamos">Órganos de gobierno, cargos sociales y equipo humano</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/asi-nos-organizamos/organigrama">Organigrama</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/normas-reguladoras/normas-reguladoras-del-cermi">Normas reguladoras del CERMI</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/planes-actuacion-anuales/">Planes de Actuación Anuales</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/memorias">Memorias anuales</a></li>
            </ul>
          </div>
        </div>
        <div class="background-white p-2-rem shadow m-top-2-rem">
          <p class="text-bold font-size-21">Información económica, presupuestaria y estadística (Art 8 Ley 19/2013 de 9
            de diciembre)</p>
          <div class="d-flex">
            <ul class="list-style-none m-right-2-rem">
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/informacion-economica#subvenciones-ayudas">Subvenciones y ayudas públicas</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none black  mix-info">Encomiendas de gestión. No hay encomiendas de gestión.</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/convenios">Convenios y acuerdos institucionales</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/informacion-economica#colaboraciones-economicas">Colaboraciones económicas privadas</a></li>
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none black  mix-info">Retribuciones. Art.12.6 Estatutos. La pertenencia al Comité Ejecutivo no comporta retribución alguna.</a></li>

              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/informacion-economica#cuentas-presupuestos ">Cuentas y Presupuestos Anuales del CERMI</a></li>

            </ul>
          </div>



        </div>

        <div class="background-white p-2-rem shadow m-top-2-rem">
          <p class="text-bold font-size-21">Calidad de información (Art 5.4 y Art 5.5 Ley 19/2013 de 9 de diciembre)</p>

          <div class="d-flex">
            <ul class="list-style-none m-right-2-rem">
              <li class="font-size-21 text-primary font-light"><a class="text-primary text-decoration-none" target="_blank" href="/accesibilidad">Accesibilidad</a></li>
            </ul>
          </div>
        </div>

        <div class="p-1-rem">
          <p>En cuanto a posiciones en redes sociales, en este enlace: https://www.CERMI.es/es/actualidad/redessociales
            , se hallan los datos identificativos de las cuentas del CERMI.
            Para mejorar el grado de transparencia corporativa, y recibir peticiones, sugerencias, propuestas y
            recomendaciones a este fin, el CERMI ha habilitado la siguiente cuenta de correo transparencia@CERMI.es , a
            la que pueden dirigirse todas las personas visitantes de la página que tengan aportaciones que efectuar en
            esta esfera.</p>
        </div>
      </div>


    </div>
    <div class="p-2-rem">
      <other-links-interest class="links-interest-box container" />
    </div>

  </div>
</template>

<script>
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import { contentStore } from '@/stores/contents';
  import EditorJs from "@/components/Global/editorJs.vue"
  export default {
    name: "Clarity",
    components: { 
      OtherLinksInterest, 
      HeroGlobal, 
      EditorJs
    },
    setup() {
      const store = contentStore();
      return {
        store
      }
    },
    mounted() {
      this.store.loadPageFixed({
        slug: this.$route.path.replace('/', '')
      });
    },
    computed: {
      entry() {
        return this.store.getPageFixed(this.$route.path.replace('/', ''))
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../styles/icons";
  @import "../styles/general";

  .black {
    color: black !important;
    margin-bottom: 0px !important;
  }
</style>